import React, { useContext } from "react";
import * as statusMenuUtils from "../../utils/StatusMenuUtils";
import { Avatar } from "antd";
import { CheckOutlined, CloseOutlined, TwitterOutlined, FacebookFilled, InstagramFilled, MailFilled } from "@ant-design/icons";
import moment from "moment/min/moment-with-locales";
import { useStaticQuery, graphql } from "gatsby";
import { Tooltip } from "antd";
import WindowSizeContext from "../../contexts/windowSizeContext";

export default (props) => {
  const { displayName, email, emailVerified, lastLoginAt, photoURL } = props.user;
  const windowSize = useContext(WindowSizeContext);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            twitterUrl
            instagramUrl
            facebookUrl
            comparacionUrl
          }
        }
      }
    `
  );

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <section className="">
      <div className="container py-6 mx-auto lg:py-10">
        <div className="flex flex-wrap overflow-hidden sm:text-center md:text-center">
          <div className="w-full overflow-hidden lg:w-1/2 xl:w-1/2">
            <div className="flex flex-wrap overflow-hidden">
              <div className="w-full px-2 overflow-hidden text-center md:text-right lg:text-right lg:px-0 lg:pr-2 sm:w-1/2 md:w-5/12 lg:w-5/12 xl:w-5/12">
                <Avatar shape="square" size={windowSize.isLargeScreenView ? 150 : 105} className="bg-blue-500" src={`${photoURL ? photoURL : ``}`}>
                  <p className={`${photoURL ? `hidden` : ``}p-0 text-6xl align-middle`}>{statusMenuUtils.getAvatarCharacterFrom(displayName)}</p>
                </Avatar>
              </div>

              <div className="w-full py-4 overflow-hidden text-center lg:text-left lg:py-6 sm:w-1/2 md:w-1/2 lg:w-7/12 xl:w-7/12">
                <div className="w-full overflow-hidden">
                  <h3 className="px-1 ">{displayName}</h3>
                </div>
                <div className="w-full overflow-hidden ">
                  <h5 className="px-1 py-0 text-blue-500 ">{email}</h5>
                </div>
                <div className="w-full overflow-hidden ">
                  <h5 className="px-1 py-0 ">
                    Verified
                    {emailVerified ? <CheckOutlined className="pl-1 text-xs text-green-600 align-middle" /> : <CloseOutlined className="pl-1 text-xs text-red-600 align-middle " />}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full overflow-hidden lg:w-1/2 xl:w-1/2">
            <div className="w-full pt-8 pb-4 overflow-hidden text-center lg:text-right lg:pb-1 lg:pt-12 lg:pr-16 ">
              <a className="p-0 py-0 mx-1 mb-1 text-sm align-middle" href={site.siteMetadata.twitterUrl} rel="noreferrer" target="_blank">
                <Tooltip title="Click to follow us on Twitter">
                  <Avatar shape="square" size={40} className="bg-blue-500">
                    <TwitterOutlined className="align-middle" />
                  </Avatar>
                </Tooltip>
              </a>
              <a className="p-0 py-0 mx-1 mb-1 text-sm align-middle" href={site.siteMetadata.facebookUrl} rel="noreferrer" target="_blank">
                <Tooltip title="Click to follow us on Facebook">
                  <Avatar shape="square" size={40} className="mx-1 bg-blue-500">
                    <FacebookFilled className="align-middle" />
                  </Avatar>
                </Tooltip>
              </a>
              <a className="p-0 py-0 mx-1 mb-1 text-sm align-middle" href={site.siteMetadata.instagramUrl} rel="noreferrer" target="_blank">
                <Tooltip title="Click to follow us on Instagram">
                  <Avatar shape="square" size={40} className="mx-1 bg-blue-500 ">
                    <InstagramFilled className="align-middle" />
                  </Avatar>
                </Tooltip>
              </a>
              <a className="p-0 py-0 mx-1 mb-1 text-sm align-middle" href={`mailto:${site.siteMetadata.twitterUrl}`} rel="noreferrer" target="_blank">
                <Tooltip title="Click to email The Comparison Company">
                  <Avatar shape="square" size={40} className="mx-1 bg-blue-500 ">
                    <MailFilled className="align-middle" />
                  </Avatar>
                </Tooltip>
              </a>
            </div>
            <div className="w-full pt-4 overflow-hidden text-center lg:text-right lg:pt-1 lg:pb-12 lg:pr-16 ">
              <p className="">
                <span className="text-blue-500">Last Login </span>
                {moment.unix(lastLoginAt / 1000).format("MMMM Do YYYY, h:mm:ss a")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
